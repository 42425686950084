<template>
	<div class="review-write">
		<div class="review-write__title-wrapper">
			<h2 class="section-title">Напишите отзыв</h2>

			<!-- <tk-button
				class="review__reset-btn"
				kind="reset-btn"
				form="review-form"
				type="reset"
			>
				Отменить
			</tk-button> -->
		</div>

		<form @submit.prevent="submit" action="#" class="review" id="review-form">
			<div
				v-for="(item, index) in config"
				:key="index"
				class="review__item review-item"
			>
				<h3 class="review-item__title">{{ item.title }}</h3>
				<div class="review-item__card">
					<p class="review-item__description">
						{{ item.description }}
					</p>
					<div class="review-item__area">
						<template v-for="itm of 5" :key="itm">
							<input
								type="radio"
								v-model="form[item.key]"
								:id="`${item.key}-${itm}`"
								:name="item.key"
								:value="itm"
							/>
							<label :for="`${item.key}-${itm}`" :title="`Оценка «${6 - itm}»`" />
						</template>
					</div>
				</div>
			</div>

			<div class="review__item review-item">
				<h3 class="review-item__title">Напишите свои впечатления от поездки</h3>
				<p class="review-item__subtitle">
					Здесь Вы можете рассказать о плюсах и минусах жилья, описать
					впечатления от поездки и поблагодарить хозяина
				</p>
				<textarea
					v-model="payload.text"
					class="review-item__textarea"
					placeholder="Текст"
				/>

				<button
					class="btn review-item__submit-btn"
          :disabled="inProcess"
					@click="showReviewThanksModal"
				>
					Отправить
				</button>
			</div>
		</form>
	</div>
</template>

<script>
const config = [
	{
		title: 'Гостеприимство',
		description: 'Оцените насколько хозяин был гостеприимен',
		key: 201
	},
	{
		title: 'Удобство расположения',
		description: 'Оцените расположение жилья',
		key: 202
	},
	{
		title: 'Общительность хозяиная',
		description:
			'Оцените насколько хозяин был общителен,не был ли он напорист и проявлял ли вежливость',
		key: 301
	},
	{
		title: 'Чистота жилья',
		description: 'Оцените насколько в жилье было чисто',
		key: 302
	}
]
const fields = {
	201: 'Общительность хозяина',
	202: 'Гостеприимство',
	301: 'Удобство расположения',
	302: 'Чистота жилья'
}

export default {
	data() {
		return {
			form: {
				201: 0,
				202: 0,
				301: 0,
				302: 0
			},
			payload: {
				id: this.$route.params.id,
				text: '',
				ratings: [
					{
						value: 1,
						target: 201
					},
					{
						value: 2,
						target: 202
					},
					{
						value: 4,
						target: 301
					},
					{
						value: 5,
						target: 302
					}
				]
			},
      inProcess: false,
		}
	},
	computed: {
		config() {
			return config
		},
		fields() {
			return fields
		}
	},
	methods: {
		async submit() {
      this.inProcess = true

			this.payload.ratings = this.payload.ratings.map((itm) => ({
				...itm,
				value: (6 - this.form[itm.target]) // какая-то магия, ибо this.form[itm.target] - кол-во пустых звездочек
			}))

			const response = await this.$api.reviews.set({ ...this.payload })

      this.inProcess = false
			if (response && response.status) {
				this.$store.commit('showNotification', { type: 'success', text: 'Отзыв успешно отправлен' })
        this.$store.commit('showModal', {
          name: 'CommonInfo',
          props: {
            text: `Спасибо за оставленный отзыв! </br> Ваши оценки повлияют на рейтинг хозяина.`
          }
        })

        this.$router.go(-1)
			} else {
				this.$store.commit('showNotification', { type: 'error', text: 'Все поля обязательны для заполнения' })
			}
		}
	}
}
</script>